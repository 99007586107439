import React, { useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { Link, navigate } from 'gatsby';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faBell } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';

import { UqAppBarV2, UqFooterV2, UqLayoutV2, UqNavbarV2 } from '@uq-components';
import BlockWithShadow from '../components/blockWithShadow';
import SEO from '../components/seo';

import { getRating } from '../helpers/v2/score-helper';

import * as styles from './scorecard.module.scss';

const epicColor = '85, 216, 254'
const goodColor = '80, 216, 187'
const fairColor = '255, 192, 106'
const poorColor = '255, 132, 116'

const RatingColors = new Map([
  ['epic', epicColor],
  ['good', goodColor],
  ['fair', fairColor],
  ['poor', poorColor],
]);

const Scorecard = props => {
  const { pageContext } = props
  let appScoreCard = pageContext.scorecardData
  let itunesData = pageContext.itunesData
  let playstoreData = pageContext.playstoreData
  const displayScore =
    appScoreCard?.timeseries[appScoreCard.timeseries.length - 1]
      ?.seven_day_average

  const displayScore7daysBefore =
    appScoreCard?.timeseries[appScoreCard.timeseries.length - 8]
      ?.seven_day_average

  const reviewItemsElements = useRef([])
  const companyDescriptionElements = useRef([])
  const appTitle =
    itunesData[appScoreCard.app_id]?.trackName ||
    playstoreData[appScoreCard.app_id]?.title ||
    appScoreCard.app_id

  const get7DaysAverage = appScoreCard =>
    appScoreCard.timeseries.map(tso => Math.round(tso.seven_day_average))
  const getScore = appScoreCard =>
    appScoreCard.timeseries.map(tso => Math.round(tso.score))
  const getXAxisLables = appScoreCard =>
    appScoreCard.timeseries.map(tso =>
      new Date(tso.day).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    )

  const getScoreColor = () => {
    return RatingColors.get(getRating(Math.round(displayScore)));
  }

  const getDeltaPoints = appScoreCard => {
    appScoreCard.previous_thirty_day_score = appScoreCard.previous_thirty_day_score
      ? appScoreCard.previous_thirty_day_score
      : displayScore
    return Math.round(displayScore - displayScore7daysBefore)
  }

  //Android chart
  const getAndroid7DaysScore = appScoreCard =>
    appScoreCard.playstore_timeseries.map(tso => Math.round(tso.score))
  const getAndroidDotColors = appScoreCard =>
    appScoreCard.playstore_timeseries.map((tso, i, arr) =>
      i < arr.length - 1 ? 'transparent' : '#707070',
    )
  const getAndroidXAxisLables = appScoreCard =>
    appScoreCard.playstore_timeseries.map(tso =>
      new Date(tso.day).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    )

  //iOS chart
  const getIos7DaysScore = appScoreCard =>
    appScoreCard.itunes_timeseries.map(tso => Math.round(tso.score))
  const getIosDotColors = appScoreCard =>
    appScoreCard.itunes_timeseries.map((tso, i, arr) =>
      i < arr.length - 1 ? 'transparent' : '#707070',
    )
  const getIosXAxisLables = appScoreCard =>
    appScoreCard.itunes_timeseries.map(tso =>
      new Date(tso.day).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    )

  const plugins = [
    {
      beforeDatasetDraw: (chartInstance, { index }) => {
        const devicePixelRatio = window.devicePixelRatio || 1
        if (index === 1) {
          const ctx = chartInstance.chart.ctx
          var gradientFill = ctx.createLinearGradient(
            0,
            0,
            0,
            Math.round(ctx.canvas.height / devicePixelRatio),
          )
          gradientFill.addColorStop(0.2, 'rgba(255, 255, 255, 0.001)')
          gradientFill.addColorStop(0.72, 'rgba(255, 255, 255, 1)')
          gradientFill.addColorStop(1, 'rgba(255, 255, 255, 1)')

          ctx.fillStyle = gradientFill
          ctx.fillRect(
            Math.round(ctx.canvas.width - (ctx.canvas.width - 33)),
            0,
            ctx.canvas.width,
            Math.round(ctx.canvas.height / devicePixelRatio - 31),
          )
        }
      },
    },
    {
      //7 days average custom gradient fill
      beforeRender: function (c) {
        const dataset = c.data.datasets[0];

        const bgGradientFill = c.ctx.createLinearGradient(0, 0, c.width, 0);
        const fgGradientFill = c.ctx.createLinearGradient(0, 0, c.width, 0);

        const step = 1 / dataset.data.length;

        dataset.data.forEach((value, i) => {
          const currentStep = (i + 1) * step;

          const rating = getRating(value);
          const ratingColor = RatingColors.get(rating);

          bgGradientFill.addColorStop(currentStep, `rgba(${ratingColor}, .4)`);
          fgGradientFill.addColorStop(currentStep, `rgba(${ratingColor}, .9)`);
        })

        var model =
          c.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].$filler.el
            ._model
        model.backgroundColor = bgGradientFill
        model.borderColor = fgGradientFill

        var view =
          c.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].$filler.el
            ._view
        view.backgroundColor = fgGradientFill
        view.borderColor = fgGradientFill
      },
    },
  ]

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    onResize: (chart, size) => {
      if (size.width > 500) {
        chart.config.data.datasets[1].barPercentage = 0.6
      } else {
        chart.config.data.datasets[1].barPercentage = size.width / 1000 + 0.1
      }
      chart.update()
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        labelColor: function (tooltipItem) {
          const borderColor = '#FFF'

          const rating = getRating(tooltipItem.value);
          const ratingColor = RatingColors.get(rating);

          const backgroundColor = tooltipItem.datasetIndex === 0
            ? `rbga(${ratingColor}, .9)`
            : '#A1A1A1';

          return {
            borderColor,
            backgroundColor,
          };
        },
        // eslint-disable-next-line
        labelTextColor: function (tooltipItem, chart) {
          return '#FFF'
        },
      },
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          offset: false,
          type: 'time',
          time: {
            unit: 'week',
            displayFormats: {
              week: 'MMM D',
            },
          },
          // ticks: {
          //   callback: (item, index) => {
          //     if (!(index % 7)) return item
          //   },
          //   autoSkip: false,
          // },
          display: true,
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            tickMarkLength: 8,
            zeroLineColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-0',
          offset: true,
          gridLines: {
            display: true,
          },
          labels: {
            show: false,
          },
        },
      ],
    },
  }

  let steppedChartOptions = {
    responsive: false,
    tooltips: {
      enabled: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 10,
        bottom: 0,
      },
    },
    legend: {
      display: false,
    },
    elements: {
      line: {
        fill: true,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          display: true,
          time: {
            unit: 'day',
            round: true,
          },
          ticks: {
            display: false, //this will remove only the label
          },
          gridLines: {
            display: true,
            color: '#FFFFFF',
            drawOnChartArea: true,
            zeroLineColor: '#FFFFFF',
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-0',
          ticks: {
            display: false, //this will remove only the label
            beginAtZero: true,
            maxTicksLimit: 102,
            stepSize: 6,
            max: 102,
          },
          gridLines: {
            display: true,
            color: '#FFFFFF',
            drawOnChartArea: true,
            zeroLineColor: '#FFFFFF',
          },
          labels: {
            show: false,
          },
        },
      ],
    },
  }

  let androidChartData = {
    datasets: [
      {
        type: 'line',
        steppedLine: true,
        data: getAndroid7DaysScore(appScoreCard),
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderColor: '#707070',
        borderWidth: 2,
        borderJoinStyle: 'round',
        pointBorderColor: getAndroidDotColors(appScoreCard),
        pointBackgroundColor: getAndroidDotColors(appScoreCard),
        pointHoverBackgroundColor: getIosDotColors(appScoreCard),
        pointHoverBorderColor: getIosDotColors(appScoreCard),
        yAxisID: 'y-axis-0',
        fill: true,
        order: 0,
      },
    ],
    labels: getAndroidXAxisLables(appScoreCard),
  }
  let iosChartData = {
    datasets: [
      {
        type: 'line',
        steppedLine: 'middle',

        data: getIos7DaysScore(appScoreCard),
        borderColor: '#707070',
        borderWidth: 2,
        borderJoinStyle: 'round',
        backgroundColor: 'rgba(0,0,0,0.1)',
        pointBorderColor: getIosDotColors(appScoreCard),
        pointBackgroundColor: getIosDotColors(appScoreCard),
        pointHoverBackgroundColor: getIosDotColors(appScoreCard),
        pointHoverBorderColor: getIosDotColors(appScoreCard),
        yAxisID: 'y-axis-0',
        fill: true,
        order: 0,
      },
    ],
    labels: getIosXAxisLables(appScoreCard),
  }
  let mainChartData = {
    datasets: [
      {
        label: ' 7 days average',
        type: 'line',
        data: get7DaysAverage(appScoreCard),
        borderColor: '#50D8BB',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        yAxisID: 'y-axis-0',
        fill: true,
        order: 0,
      },
      {
        type: 'bar',
        label: ' unitQ Score',
        data: getScore(appScoreCard),
        backgroundColor: '#A1A1A1',
        borderColor: '#888888',
        borderWidth: 0,
        hoverBackgroundColor: '#888888',
        hoverBorderColor: '#888888',
        yAxisID: 'y-axis-0',
        fill: false,
        order: 4,
        barPercentage: 0.6,
      },
    ],
    labels: getXAxisLables(appScoreCard),
  }

  const reviewSourceJSX = source => {
    if (source === 'playstore') {
      return (
        <div className="ml-3 flex-grow-1">
          {' '}
          <FontAwesomeIcon
            icon={faGooglePlay}
            size="1x"
            className="source-icon"
          />
          <span className="source-name ml-2 d-none d-sm-inline">Android</span>
        </div>
      )
    } else if (source === 'itunes') {
      return (
        <div className="ml-3 flex-grow-1">
          {' '}
          <FontAwesomeIcon
            icon={faAppStore}
            size="1x"
            className="source-icon"
          />
          <span className="source-name ml-2 d-none d-sm-inline">iOS</span>
        </div>
      )
    } else {
      return <></>
    }
  }

  const starRatingJSX = rating => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faStar}
          size="sm"
          className={`${rating >= 1 ? 'star-checked' : 'star'}`}
        />
        <FontAwesomeIcon
          icon={faStar}
          size="sm"
          className={`${rating >= 2 ? 'star-checked' : 'star'}`}
        />
        <FontAwesomeIcon
          icon={faStar}
          size="sm"
          className={`${rating >= 3 ? 'star-checked' : 'star'}`}
        />
        <FontAwesomeIcon
          icon={faStar}
          size="sm"
          className={`${rating >= 4 ? 'star-checked' : 'star'}`}
        />
        <FontAwesomeIcon
          icon={faStar}
          size="sm"
          className={`${rating >= 5 ? 'star-checked' : 'star'}`}
        />
      </div>
    )
  }
  const redBannerJSX = () => {
    if (getDeltaPoints(appScoreCard) < -5) {
      return (
        <div className="red-banner">
          <div className="red-banner-text pb-4 pt-4">
            <FontAwesomeIcon icon={faBell} size="sm" />
            &nbsp;&nbsp; Quality degradation at{' '}
            {itunesData[appScoreCard.app_id]?.trackName ||
              playstoreData[appScoreCard.app_id]?.title ||
              appScoreCard.app_id}
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faBell} size="sm" />
            <br />
            Users report higher volume of Quality Issues than usual
          </div>
          <div className="d-flex justify-content-center">
            <Button
              variant="report-redonred"
              className="ml-0"
              onClick={() => reportQualityIssue()}
            >
              Report Quality Issue
            </Button>
          </div>
        </div>
      )
    }
  }
  const topReviewsJSX = appScoreCard.quality_issues.map((edge, i) => {
    const getRef = element => reviewItemsElements.current.push(element)
    if (edge.content) {
      return (
        <div className="top-reviews-item" key={i}>
          <div className="d-flex align-items-center">
            {starRatingJSX(edge.rating)} {reviewSourceJSX(edge.source)}
            <div className="review-date">
              {new Date(edge.date).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
            </div>
          </div>
          <div className="max-lines" ref={getRef}>
            <div className="top-reviews-title mt-3">{edge.title}</div>
            <div className="top-reviews-content">{edge.content}</div>
          </div>
          <div className="full-review-link-wrapper mt-3">
            <button className="full-review-link" onClick={() => expandReview(i)}>
              Full review
            </button>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  })

  const companyDescriptionJSX = () => {
    const getRef = element => companyDescriptionElements.current.push(element)
    return (
      <div className="top-reviews-item">
        <div className="max-lines" ref={getRef}>
          <div className="top-reviews-content mt-3">
            <pre className="top-reviews-content">
              {itunesData[appScoreCard.app_id]?.description ||
                playstoreData[appScoreCard.app_id]?.description ||
                'No description found'}
            </pre>
          </div>
        </div>
        <div className="read-more-link-wrapper mt-3">
          <button className="read-more-link" onClick={() => expandDescription()}>
            READ MORE
          </button>
        </div>
      </div>
    )
  }

  const check = el => {
    if (el) {
      var curOverf = el.style.overflow

      if (!curOverf || curOverf === 'visible') el.style.overflow = 'hidden'

      var isOverflowing =
        el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

      el.style.overflow = curOverf
      return isOverflowing
    } else {
      return false
    }
  }
  const expandReview = index => {
    reviewItemsElements.current[index].style.maxHeight = 'unset'
    reviewItemsElements.current[index].style.overflow = 'visible'
    reviewItemsElements.current[index].nextElementSibling.style.display = 'none'
  }
  const expandDescription = () => {
    companyDescriptionElements.current[0].style.maxHeight = 'unset'
    companyDescriptionElements.current[0].style.overflow = 'visible'
    companyDescriptionElements.current[0].nextElementSibling.style.display =
      'none'
  }

  const reportQualityIssue = () => {
    navigate('/report-quality-issue/', {
      state: { appid: appScoreCard.app_id },
    }) //navigate to report quality issue page
  }

  useLayoutEffect(() => {
    window.setTimeout(() => {
      reviewItemsElements.current.forEach((el) => {
        if (el) {
          let SeeMoreButton = el.nextElementSibling
          if (!check(el)) {
            SeeMoreButton.style.display = 'none'
          } else {
            SeeMoreButton.style.display = 'block'
          }
        }
      })
      companyDescriptionElements.current.forEach((el) => {
        if (el) {
          let SeeMoreButton = el.nextElementSibling
          if (!check(el)) {
            SeeMoreButton.style.display = 'none'
          } else {
            SeeMoreButton.style.display = 'block'
          }
        }
      })
    }, 120)
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cse.google.com/cse.js?cx=650b354a0703e98af'
    script.async = true

    document.body.appendChild(script)

    function handleResize() {
      reviewItemsElements.current.forEach((el) => {
        if (el) {
          let SeeMoreButton = el.nextElementSibling
          if (!check(el)) {
            SeeMoreButton.style.display = 'none'
          } else {
            SeeMoreButton.style.display = 'block'
          }
        }
      })
      companyDescriptionElements.current.forEach((el) => {
        if (el) {
          let SeeMoreButton = el.nextElementSibling
          if (!check(el)) {
            SeeMoreButton.style.display = 'none'
          } else {
            SeeMoreButton.style.display = 'block'
          }
        }
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      document.body.removeChild(script)
    }
  })

  const dataSourceDateCreationMetadata = useMemo(() => {
    return [{
      name: 'Scorecard data source info',
      description: 'The date in which the artifact was generated (UTC)',
      date: props?.pageContext?.scorecardData?.last_updated,
    }];
  }, [props?.pageContext?.scorecardData?.last_updated]);

  return (
    <UqLayoutV2>
      <UqAppBarV2
        static
        className={styles.appbar}
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container scorecard-page">
          <SEO
            title={`${appTitle}'s unitQ Product Quality Score`}
            description={
              `Quality issues and problems reported for ${appTitle}.
            Their unitQ Score is based on public feedback data that relates to product quality issues.
            See ${appTitle}'s product quality.`
            }
            meta={dataSourceDateCreationMetadata}
          />
          <Container fluid className="px-0 main">
            <Row>
              <Col className="scorecard-header header-row-background" />
            </Row>
            <Row noGutters className="justify-content-center">
              <Col className="scorecard-header-wrapper mb-5">
                {redBannerJSX()}
                <div className="content-wrapper margin-auto">
                  <div className="search-wrapper d-flex justify-content-end">
                    <div
                      className="gcse-search"
                      data-enable-auto-complete="true"
                      data-auto-complete-max-completions="5"
                      data-auto-complete-match-type="any"
                    />
                  </div>
                  <BlockWithShadow className="scorecard-wrapper">
                    <Container className="scorecard-content scorecard-content-container">
                      <Row>
                        <Col>
                          <div
                            className="scorecard-logo shadow-block"
                            style={{
                              backgroundImage: `url(${itunesData[
                                appScoreCard.app_id
                              ]?.artworkUrl512 ||
                                playstoreData[appScoreCard.app_id]?.icon})`,
                            }}
                          />
                          <Container className="d-flex score-header-custom-wrap">
                            <div className="score-section">
                              <div
                                className="score-circle"
                                style={{
                                  background: `rgba(${getScoreColor(appScoreCard)}, 1)`,
                                }}
                              >
                                <div className="score-value">
                                  {Math.round(displayScore)}
                                </div>
                                <div className="score-value-code">
                                  {getRating(displayScore)}
                                </div>
                              </div>
                              {/* <div className="score-section-desc">
                            <div className="score-blue-text">unitQ Score</div>
                            <div className="score-normal-text">
                              30 Day Average
                            </div>
                          </div> */}
                            </div>
                            <div className="score-desc-section">
                              <h1 className="score-desc-h1">
                                <span className="capitalize">{appTitle}</span>{' '}
                                |&nbsp;unitQ&nbsp;Score
                              </h1>
                              <div className="score-desc-text mt-3">
                                The unitQ score is the fraction of public feedback
                                data (iOS & Android) that mentions quality issues.
                                <br />
                                100 = No Issues. 50 = Some Issues. 0 = All Issues
                              </div>
                              <div className="score-delta-container d-flex">
                                <div className="score-delta-cell  border-right mr-4">
                                  <span
                                    className={
                                      getDeltaPoints(appScoreCard) < 0
                                        ? 'score-orange-text'
                                        : 'score-green-text'
                                    }
                                  >
                                    {getDeltaPoints(appScoreCard)}
                                    &nbsp;
                                    {Math.abs(getDeltaPoints(appScoreCard)) === 1
                                      ? 'Point'
                                      : 'Points'}
                                  </span>{' '}
                                  <span className="score-normal-text">
                                    <nobr>Past 7 Days</nobr>
                                  </span>
                                </div>
                                <div className="score-delta-cell">
                                  <span className="score-normal-text">
                                    Category:
                                  </span>
                                  &nbsp;
                                  <span className="score-normal-text">
                                    <b>
                                      {itunesData[appScoreCard.app_id]
                                        ?.primaryGenreName ||
                                        playstoreData[appScoreCard.app_id]
                                          ?.genre ||
                                        'No Category'}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Container>
                          <Container className="d-flex justify-content-end d-flex d-lg-none">
                            <Container className="mt-3 pt-2 d-flex flex-wrap">
                              <Row className="score-w-100">
                                <Col className="p-0">
                                  <div className="d-flex justify-content-end flex-grow-1">
                                    <div className="score-normal-text pr-2 d-flex align-content-right">
                                      <span className="self-align-center">
                                        Work at{' '}
                                        {itunesData[appScoreCard.app_id]
                                          ?.artistName ||
                                          playstoreData[appScoreCard.app_id]
                                            ?.developer ||
                                          appScoreCard.app_id}
                                        ? Unlock additional insights
                                      </span>
                                    </div>
                                    <Link
                                      to="/request-demo/"
                                      className="link-no-style"
                                    >
                                      <Button
                                        variant="get-access"
                                        className="ml-0 mr-0"
                                      >
                                        <nobr>Get&nbsp;Access</nobr>
                                      </Button>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Container>
                          <Container className="mt-4 mb-4">
                            <div className="score-blue-header ml-3">
                              30-Day Trend
                            </div>
                          </Container>
                          <Container className="d-flex justify-content-end">
                            <Container className="legend-container mb-3 pb-2 d-flex flex-wrap">
                              <Row xs={1} md={2} className="score-w-100">
                                <Col xs={12} md={5} lg={5}>
                                  <div className="d-flex align-center justify-content-start">
                                    <div
                                      className="legend-square poor"
                                      style={{ backgroundColor: `rgba(${poorColor}, 1)` }}
                                    >
                                      &nbsp;
                                    </div>
                                    <div className="score-normal-text pl-2 pr-4">
                                      Poor
                                    </div>
                                    <div
                                      className="legend-square fair"
                                      style={{ backgroundColor: `rgba(${fairColor}, 1)` }}
                                    >
                                      &nbsp;
                                    </div>
                                    <div className="score-normal-text pl-2 pr-4">
                                      Fair
                                    </div>
                                    <div
                                      className="legend-square good"
                                      style={{ backgroundColor: `rgba(${goodColor}, 1)` }}
                                    >
                                      &nbsp;
                                    </div>
                                    <div className="score-normal-text pl-2 pr-4">
                                      Good
                                    </div>
                                    <div
                                      className="legend-square epic"
                                      style={{ backgroundColor: `rgba(${epicColor}, 1)` }}
                                    >
                                      &nbsp;
                                    </div>
                                    <div className="score-normal-text pl-2 pr-4">
                                      Epic
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xs={{ order: 'first', span: 12 }}
                                  md={{ order: 'last', span: 7 }}
                                  lg={{ order: 'last', span: 7 }}
                                >
                                  <div className="justify-content-end flex-grow-1 d-none d-lg-flex">
                                    <div className="score-normal-text pl-2 pr-3 d-flex align-content-right">
                                      <span className="self-align-center">
                                        Work at{' '}
                                        {itunesData[appScoreCard.app_id]
                                          ?.artistName ||
                                          playstoreData[appScoreCard.app_id]
                                            ?.developer ||
                                          appScoreCard.app_id}
                                        ? Unlock additional insights
                                      </span>
                                    </div>
                                    <Link
                                      to="/request-demo/"
                                      className="link-no-style"
                                    >
                                      <Button
                                        variant="get-access"
                                        className="ml-0 mr-0"
                                      >
                                        <nobr>Get&nbsp;Access</nobr>
                                      </Button>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Container>
                          <div
                            id="chart-wrapper"
                            style={{ position: 'relative', height: '210px' }}
                          >
                            {' '}
                            <Bar
                              data={mainChartData}
                              width={100}
                              height={20}
                              options={options}
                              plugins={plugins}
                            />
                          </div>
                          <Container className="d-flex justify-content-end">
                            <Container className="legend-container mb-3 pb-2 d-flex" />
                          </Container>
                          <Container className="d-flex justify-content-center flex-wrap">
                            <div className="score-tile mb-4 mt-3">
                              Android unitQ Score
                              <div>
                                <Bar
                                  data={androidChartData}
                                  width={276}
                                  height={168}
                                  options={steppedChartOptions}
                                />
                              </div>
                              <div
                                className="justify-content-start flex-wrap pt-3 d-flex d-lg-none"
                                style={{
                                  borderTop: '1px solid #DCDCDC',
                                }}
                              >
                                <div>Android User Feedback</div>
                                <div className="d-flex flex-row score-feedback-row pb-0">
                                  <div className="percent-dot-android mr-3">
                                    {Math.round(
                                      (appScoreCard.thirty_day_playstore_volume *
                                        100) /
                                      appScoreCard.thirty_day_volume,
                                    )}
                                    %
                                  </div>
                                  <div>
                                    <span className="platform-title android">
                                      Android
                                    </span>{' '}
                                    <br />{' '}
                                    <span>
                                      {appScoreCard.thirty_day_playstore_volume}{' '}
                                      Reports
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="score-tile mb-4 mt-3">
                              iOS unitQ Score
                              <div>
                                <Bar
                                  data={iosChartData}
                                  width={276}
                                  height={168}
                                  options={steppedChartOptions}
                                />
                              </div>
                              <div
                                className="justify-content-start flex-wrap pt-3 d-flex d-lg-none"
                                style={{
                                  borderTop: '1px solid #DCDCDC',
                                }}
                              >
                                <div>iOS User Feedback</div>
                                <div className="d-flex flex-row score-feedback-row">
                                  <div className="percent-dot-ios mr-3">
                                    {Math.round(
                                      (appScoreCard.thirty_day_ios_volume * 100) /
                                      appScoreCard.thirty_day_volume,
                                    )}
                                    %
                                  </div>
                                  <div>
                                    <span className="platform-title ios">
                                      iOS
                                    </span>{' '}
                                    <br />
                                    <span>
                                      {appScoreCard.thirty_day_ios_volume} Reports
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="score-tile mb-4 mt-3 d-none d-lg-block">
                              User Feedback
                              <div className="d-flex justify-content-around flex-wrap">
                                <div
                                  className="d-flex flex-row score-feedback-row"
                                  style={{
                                    borderBottom: '1px solid #DCDCDC',
                                  }}
                                >
                                  <div className="percent-dot-ios mr-3">
                                    {Math.round(
                                      (appScoreCard.thirty_day_ios_volume * 100) /
                                      appScoreCard.thirty_day_volume,
                                    )}
                                    %
                                  </div>
                                  <div>
                                    <span className="platform-title ios">
                                      iOS
                                    </span>{' '}
                                    <br />
                                    <span>
                                      {appScoreCard.thirty_day_ios_volume} Reports
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-row score-feedback-row pb-0">
                                  <div className="percent-dot-android mr-3">
                                    {Math.round(
                                      (appScoreCard.thirty_day_playstore_volume *
                                        100) /
                                      appScoreCard.thirty_day_volume,
                                    )}
                                    %
                                  </div>
                                  <div>
                                    <span className="platform-title android">
                                      Android
                                    </span>{' '}
                                    <br />{' '}
                                    <span>
                                      {appScoreCard.thirty_day_playstore_volume}{' '}
                                      Reports
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Container>
                          <Container className="mb-4">
                            <div className="d-flex justify-content-center">
                              <Button
                                variant="report"
                                className="ml-0"
                                onClick={() => reportQualityIssue()}
                              >
                                Report Quality Issue
                              </Button>
                            </div>
                          </Container>
                          <Container>
                            <div className="score-divider" />
                          </Container>
                          <Container className="mt-4 mb-4">
                            <div className="score-blue-header ml-3">
                              <span className="capitalize">
                                {itunesData[appScoreCard.app_id]?.trackName ||
                                  playstoreData[appScoreCard.app_id]?.title ||
                                  appScoreCard.app_id}
                                &nbsp;
                              </span>
                              - Latest Quality Issues
                            </div>
                          </Container>
                          <Container className="top-reviews-container mb-4">
                            {topReviewsJSX}
                          </Container>
                          <Container className="mt-4 mb-4">
                            <div className="score-blue-header ml-3">
                              <span className="capitalize">
                                {itunesData[appScoreCard.app_id]?.trackName ||
                                  playstoreData[appScoreCard.app_id]?.title ||
                                  appScoreCard.app_id}
                                &nbsp;
                              </span>
                              - Product Description
                            </div>
                            {companyDescriptionJSX()}
                          </Container>
                          <Container className="mt-4 mb-4">
                            <div className="score-blue-header-1 ml-3">
                              Want to improve your unitQ Score by 20% within 30
                              days?
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                              <Link to="/request-demo/" className="link-no-style">
                                <Button variant="secondary">
                                  Request a Custom Demo
                                </Button>
                              </Link>
                            </div>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </BlockWithShadow>
                  <Container className="mt-1">
                    <div className="disclamer pl-3 pr-3">
                      All intellectual property rights, including trademarks and
                      word marks, are the property of respective owners
                    </div>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}

export default Scorecard
